<template>
  <div id="myproduct" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Download PIM Data</p>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="col-12">
          <div class="m-4">
            <b-table
              :items="items"
              :fields="fields"
              thead-tr-class="d-none"
              hover
              show-empty
              responsive
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(file)="row">
                <div class="row pl-4 m-1">
                  <!-- <b-icon icon="folder"  aria-hidden="true" scale="1.6"></b-icon> -->
                  <b-avatar icon="folder" variant="light" size="2em"></b-avatar>
                  <div class="pl-4">{{ row.item.file }}</div>
                </div>
              </template>
              <template #cell(actions)="row">
                {{ row.item.row }}
                <b-icon
                  type="button"
                  icon="download"
                  aria-hidden="true"
                  scale="1"
                  v-b-tooltip.hover.right="`ดาวน์โหลด ${row.item.file} `"
                  @click="download(row.item)"
                ></b-icon>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileDownload from "js-file-download";
import Axios from "axios";
export default {
  name: "DownloadCSVData",
  data() {
    return {
      fields: ["file", "actions"],
      items: [
        {
          file: " Pim Product",
          filename: "PIM_PRODUCT.csv",
          key: "product",
          class: "text-left",
        },
        {
          file: " Pim Image",
          filename: "PIM_IMAGE.xlsx",
          key: "image",
          class: "text-left",
        },
        {
          file: " Pim NO HTML",
          filename: "PIM_NOHTML.csv",
          key: "nohtml",
          class: "text-left",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    async download(row) {
      try {
        let data = { type: row.key };
        let getdata = await this.$serviceAPI.call_API(
          "post",
          `report/getreportproduct`,
          data,
          1
        );
        if (getdata.message == "OK") {
          await this.downloadItem(row.filename);
        }
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async downloadItem(filename) {
      try {
        let url = `https://pim.powerbuy.co.th/record_file/content_file/pim_gen_file/${filename}`;
        // console.log(url);
        Axios.get(url, { responseType: "blob" })
          .then((response) => {
            // console.log(response);
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
  },
};
</script>

<style></style>
